
import { Component, Vue } from '@/lib/decorator';

import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import PaginationBlock from '@/components/Pagination.vue';
import FilterInstant from '@/components/FilterInstant.vue';

import AppTable from '@/components/ui/table/Table.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import PartnerEntityModule from '@/store/partners/entity';
import PartnersModule from '@/store/partners';
import PartnerLogsModule from '@/store/partners/logs';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    AppCol,
    AppRow,
    TextDatetime,
    AppTable,
    PaginationBlock,
    FilterInstant,
    TitleReturn,
    TabsNav,
    GuiLoader,
  },
  beforeMount() {
    this.partnerId = this.$route.params.partnerId as string;
  },
  mounted() {
    PartnerEntityModule.initEdit(this.partnerId);
    PartnerLogsModule.init(this.partnerId);
  },
})
export default class EmployeesEntityIndex extends Vue {
  partnerId = '';

  get tabsNav() {
    return PartnerEntityModule.tabsNav;
  }

  get settings(): PageInterface {
    return PartnerLogsModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return PartnerLogsModule.filter.filterSettings;
  }

  get currentTab(): string {
    return PartnerEntityModule.tabsNav.logs?.id;
  }

  get titlePage(): string {
    return PartnersModule.pageSettings.titleEdit;
  }

  sort(header: { id: string; sort: { value: string } }): void {
    PartnerLogsModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  selectAmount(value: string): void {
    PartnerLogsModule.updatePageAmountItems(value);
  }

  async updateList(): Promise<void> {
    await PartnerLogsModule.setPageToBegin();
    if (PartnersModule.pageSettings.titleEdit === '') {
      await PartnerEntityModule.initEdit(this.partnerId);
    }

    await PartnerLogsModule.filter.updateFilter();
    await PartnerLogsModule.updateList();
  }

  returnBefore() {
    this.$router.push({ name: 'partners' });
  }
}
