import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import { PageSort } from '@/lib/layouts/page/page.interface';

/**
 * Returns processed employee logs table
 *
 * @param {Object} table employee logs data
 * @returns {Object} tableInfo
 */
export function prepareList(table: TableApiInterface, sort: PageSort) {
  const uiTable = new UITable(table as any);

  return uiTable
    .removeColumn('id')
    .removeColumn('type')
    .removeColumn('clientMarketShiftId')
    .removeColumn('partnerId')

    .setSortableValues(Object.values(sort))

    .getTable() as any;
}
